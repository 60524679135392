<template>
  <div class="admin-wrap">
    <a class="btn-new" href="/admin/spots/0"></a>
    <AdminMenu active-item="spots" />
    <div class="admin-main">
      <AdminHeader title="診間與休息區管理" />
      <div class="office-list">
        <div class="site-search site-search--admin">
          <input type="text" v-model="keyword">
          <button>搜尋</button>
        </div>
        <table class="admin-table sortable">
          <thead>
            <tr>
              <th>編號</th>
              <th>名稱</th>
              <th>位置</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="spot in filteredList" :key="spot">
              <td>{{spot.id}}</td>
              <td>{{spot.name}}</td>
              <td>{{spot.location}}</td>
              <td>
                <a class="admin-btn" :href="`/admin/spots/${spot.id}`">編輯</a>
                <a class="admin-btn" href="javascript:void(0);" @click="showConfirmDelete(spot.id)">刪除</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <confirm-delete-dialog :visible="isShowConfirmDelete" title="是否確認刪除？" message="刪除後將不再顯示，請確認是否刪除此診間或休息區。" @confirm="onDelete" @cancel="hideConfirmDelete" />
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import ConfirmDeleteDialog from '@/components/ConfirmDeleteDialog'
import { apiGetSpots, apiDeleteSpot } from '@/api/v1/admin'

export default {
  name: 'Spots',
  components: { AdminMenu, AdminHeader, ConfirmDeleteDialog },
  created () {
    this.fetchData()
  },
  data () {
    return {
      isShowConfirmDelete: false,
      spots: [],
      selectedItemId: 0,
      keyword: ''
    }
  },
  computed: {
    filteredList () {
      return this.spots.filter(s => s.name.includes(this.keyword))
    }
  },
  methods: {
    fetchData () {
      console.log('fetch data')
      apiGetSpots().then(data => {
        console.log(data)
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.spots = data
        }
      })
    },
    showConfirmDelete (itemId) {
      this.isShowConfirmDelete = true
      this.selectedItemId = itemId
    },
    hideConfirmDelete () {
      this.isShowConfirmDelete = false
      this.selectedItemId = 0
    },
    onDelete () {
      console.log(`deleting item id ${this.selectedItemId}`)
      apiDeleteSpot(this.selectedItemId).then(data => {
        this.hideConfirmDelete()
        if (data.error) {
          this.$swal('刪除失敗', data.message, 'warning')
        }
        this.fetchData()
      })
    }
  }
}
</script>

<style>

</style>
